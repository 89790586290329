.custom-file-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
    .custom-file-label {
      font-size: 14px;
      margin-bottom: 8px;
      color: #333;
      font-weight: 500;
  
      .required {
        color: red;
      }
    }
  
    .custom-file-input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
  
      &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
      }
    }
  
    .error-text {
      color: red;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  
  .error .custom-file-input {
    border-color: red;
  }
  
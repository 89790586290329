.HalfScreenLogo-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .right-side {
    height: 95%;
    width: 100%;

    .background-image {
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url("../../common/assats/logos/halfScreenLogo.png");
      background-size: cover;
      background-position: center;
      border-radius: 10px;

      .lang-dropdown{
        position: absolute;
        top: 10px;
        right: 20px;
        width: 4rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF33;
        border: 0;
        border-radius: 1rem !important;
      }
    }
  }

  .left-side {
    flex: 1;

    .inner-left-side {
      height: 95%;
      // width: 95%;
      width: 100%;

      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-wrapper {
        width: 100%;
        // height: 7%;
      }

      .children-wrapper {
      }

      .footer-wrapper {
        width: 100%;
        display: flex;
        // flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .full-width-height {
          width: 100vw;
          height: 100vh;
        }

        .right-part {
          width: 50%;

          // .inner-right-part {
          //     height: 95%;
          //     width: 95%;
          //     border-radius: 10px;
          // }
        }

        .form-container {
        }

        .title-group {
          margin-bottom: 40px;
        }

        .group-spacing {
          margin-bottom: 20px;
        }

        .group-center {
          display: flex;
          justify-content: center;
        }

        .terms-and-privacy {
          width: 30px;
          display: flex;
          justify-content: flex-end;
          gap: 10%;

          .link {
            color: rgba(47, 117, 218, 1);
          }
        }

        .powered-by-wrapper {

          .acc-logo-small{
            height: max-content;
            width: 5rem;
          }
        }
      }
    }
  }
}

// }

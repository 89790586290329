.dynamic-page {
  border-radius: 10px;
  width: 100%;
  height: 100%;

  .content {
    background-color: #fff !important;
    width: 100% !important;
  }
}

.dynamic-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  padding: 0 7rem;

  .header-section {
    text-align: start;
    padding-top: 3rem;
  }

  .sub-header-section {
    text-align: start;
  }

  .iframe-container {
    height: 100%;
    padding: 5px;
    background-color: #fff;
    border-radius: 10px;

    .container {
      border: 1px solid red;
    }
  }
}

.custom-terms-link {
  position: absolute;
  cursor: pointer;
}

@use '../../../common/consts/colors.scss' as vars;

.table-page-main-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    overflow: hidden;

    .table-header {
        width: 100%;
        height: 30%;
        background-color: vars.$secondaryBackground;
        border-bottom: 1px solid vars.$borderColor;

    }

    .table-content {
        width: 100%;
        height: 70%;
        background-color: vars.$white;
    }

}
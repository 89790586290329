@use "../../../common/consts/colors.scss" as color;

.table-header-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 25px 29px;
    background-color: #F2F2F7;
    .table-status-headline {
        width: 23%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        &.flex-start{
            justify-content: flex-start;
        }

        .main-headline {
            font-size: 1.7em;
            font-weight: 600;
        }

        .total-text {
            font-size: 1.6em;
            font-weight: 800;


        }

        .second-headline-container {
            font-size: .8em;
        }


    }

    .patients-cards {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }


    .view-all-text{
        cursor: pointer;
        width: max-content;
    }

}

.custom-header-container-class{
    padding: 0px!important;
    border-radius: 10px!important;
    background-color: color.$white!important;
}
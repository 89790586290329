.fota-modal-container {
    .modal-children {
        display: flex;
        flex-direction: column;
        align-items: start;

        label {
            margin-bottom: 0.5rem;
        }
    }

    .modal-body p {
        text-align: start;
    }

    .custom-file-input-container {
        width: 100%;
    }

    .upload-progress-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }

    .upload-progress-container p {
        margin: 8px 0;
        text-align: start;
    }

    progress {
        width: 100%;
        height: 20px;
    }
}
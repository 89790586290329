
:root {
  // Font sizes
    --global-font-size: 1rem;
    --global-font-size-small-big: 1.1rem;
    --global-font-size-small-med: 1.3rem;
    --global-font-size-big: 1.5rem;
    --global-font-size-small: 0.8rem;
    --global-font-size-extra-small: 0.7rem;
    --global-font-size-medium: 0.9rem;
    // Header font sizes
    --global-header-one-font-size: 2.5rem;
    
    
    --global-line-height: 1.5;
  }
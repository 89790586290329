@use "../../common/consts/colors.scss" as colors;

.custom-date-dialog {
  background-color: colors.$white !important;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  min-height: 500px;
  width: min-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .custom-cancel-btn {
    background-color: white;
    border: 1.2px solid #2F75DA;
    color: #2F75DA;
  }

  .btn-hover-effect {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      background-color: #2F75DA;
      color: white;
  
      svg {
        path {
          stroke: white;
          transition: stroke 0.3s ease-in-out; 
        }
      }
    }
  }
}

.p-component-overlay {
  top: 154px !important;
  left: 45px !important;
}

.rdrCalendarWrapper {
  background-color: colors.$white !important;
  border-radius: 8px;
  padding: 12px;
  font-family: "Inter", sans-serif;
}

.rdrMonthAndYearWrapper {
  background-color: colors.$white !important;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}

.rdrMonths {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.rdrDay {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.rdrDayNumber span {
  background-color: transparent !important;
}

.rdrSelected {
  background-color: colors.$white !important;
  border-radius: 6px;
}

.rdrDay:hover .rdrDayNumber span {
  background-color: colors.$white !important;
  border-radius: 6px;
}

.rdrStaticRanges {
  background-color: colors.$white !important;
  font-size: 14px;
  padding: 8px;
}

.date-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 10px;

  .custom-button-date-picker {
    width: 100%;
    height: 40px;

    background-color: white;
    border: 1px solid #D1D1D6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    cursor: pointer;

    justify-content: space-between;

    svg:last-child {
      margin-left: auto;
    }

    span {
      color: colors.$toastGeneralBorderColor;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .lable-changed{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &[title]:hover::after {
      content: attr(title);
      position: absolute;
      background-color: #0000006d;
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      top: 100%;
      left: 0;
      white-space: nowrap;
      z-index: 10;
    }
  }
}

.calendar-buttons-container {
  button {
    width: 150px;
    height: 50px;
    margin-left: 1rem;
  }
}


@media (max-width: 1200px) {

  .rdrStaticRanges,
  .rdrMonthAndYearWrapper,
  .rdrMonth {
    font-size: 12px;
  }
}
.table-main-container {
    height: 100%;
    width: 100%;
    background-color: #FBFBFB;

    .filters-container {
        width: 100%;
        height: 13%;

    }

    .sort-wrapper {
        height: 7%;
        display: flex;
        flex-direction: column;
    }

    .table-wrapper {
        width: 100%;
        height: 46vh;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 1rem;
    }
}


button{
    border-radius: 8px !important;
    height: 59px;
    width: 280px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Inter', sans-serif !important;
}

button.small{
    width: 129px;
}

.custom-select.form-control,
.custom-input.form-control {
    height: 65px; /* Adjust height as needed */
    color: #757575;
}


.input-group-text{
    color: #757575 !important;
}


.dropdown-wrapper {
    position: relative;
}

.dropdown-wrapper .form-control {
    padding: 0px 20px; /* Adjust according to your SVG width */
}

input.form-control {
    padding: 0px 20px;
}

.custom-dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 20px; /* Adjust according to your needs */
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-dropdown-arrow svg {
    width: 1rem; /* Adjust according to your needs */
    height: 1rem; /* Adjust according to your needs */
    color: #000; /* Set the color of the SVG */
}

.global-big-label {
    font-size: 38px;
    line-height: 41px;
    font-weight: 500;
}

.global-sub-label {
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
}

.global-input {
    width: 28rem!important;
    height: 65px;
}

.global-button {
    font-weight: 600;
    width: 28rem!important;
    height: 65px;
}

.global-font-weight-600 {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer;
}

/* COLLORS */
.system-red {
    background: #FF3B30;
}

.system-orange {
    background: rgba(255, 149, 0, 1);
}

.system-yellow {
    background: rgba(255, 204, 0, 1);
}

.system-green {
    background: rgba(52, 199, 89, 1);
}

.system-mint {
    background: rgba(0, 199, 190, 1);
}

.system-teal {
    background: rgba(48, 176, 199, 1);
}

.system-cyan {
    background: rgba(50, 173, 230, 1);
}

.system-blue {
    background: rgba(0, 122, 255, 1);
}

.system-indigo {
    background: rgba(88, 86, 214, 1);
}

.system-purple {
    background: rgba(175, 82, 222, 1);
}

.brand-color-1o {
    background: rgba(47, 117, 218, 1);
}

.brand-border-color {
    border-color: rgba(47, 117, 218, 1);
}

.brand-color-2o {
    background: rgba(125, 169, 232, 1);
}

.brand-color-3o {
    background: rgba(177, 203, 241, 1);
}

.brand-color-4o {
    background: rgba(220, 232, 249, 1);
}

.global-gradient {
    background: linear-gradient(136.79deg, #2232C0 1%, #3FD6FF 94.16%, #3FCBC9 144.32%);
}

/* GRAY SHADES */
.system-gray-1 {
    background: rgba(142, 142, 147, 1);
}

.system-gray-2 {
    background: rgba(142, 142, 147, 1);
}

.system-gray-3 {
    background: rgba(174, 174, 178, 1);
}

.system-gray-4 {
    background: rgba(199, 199, 204, 1);
}

.system-gray-5 {
    background: rgba(209, 209, 214, 1);
}

.system-gray-6 {
    background: rgba(229, 229, 234, 1);
}

/* TEXT  */
.text-black {
    color: rgba(0, 0, 0, 1);
}

.text-3o {
    color: rgba(117, 117, 117, 1);
}

.text-2o {
    color: rgba(180, 180, 180, 1);
}

.text-5o {
    color: rgba(209, 209, 214, 1);
}

.text-primary {
    color: rgba(47, 117, 218, 1);
}

.global-background-white {
    background: rgba(255, 255, 255, 1);
}

.global-background-secondary {
    background: rgba(242, 242, 247, 1);
}


/* TYPOGRAPHY */
.title3-bold {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.44999998807907104px;
    text-align: left;
}

.headline {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.4300000071525574px;
    text-align: left;
}

.initials {

    font-size: 23.9px;
    font-weight: 600;
    line-height: 33.46px;
    text-align: left;
}

.flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.full-width-height {
    width: 100vw;
    height: 100vh;

}

.form-control.is-invalid{
    background-image: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.invalid-field .custom-dropown-label,
.invalid-field .custom-input-label,
.invalid-field .custom-input-label-base,
.invalid-field .custom-input-label-number {
    color: rgb(220, 53, 69) !important;
}
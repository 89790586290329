@use "../../common//consts/colors.scss" as vars;

.patient {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .rounded-status {
    background-color: vars.$statusBorderColor;
    color: vars.$statusTextColor;
    padding: 0px 10px;
    border-radius: 100px;
    line-height: 21px;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    justify-content: center;
    display: flex;
    font-size: 13px;
    height: 25.5px;
    width: fit-content;
    margin-top: 15.3px;
  }

  .patient-header {
    background-color: #fbfbfb;
    height: 146px;
    width: 100%;
    display: flex;
    padding: 2rem 5rem;
    flex-direction: column;

    .identity-info {
      display: flex;

      .generic-form {
        width: 100%;
      }

      h1 {
        font-size: 30px;
        font-weight: 500;
        line-height: 21px;
        margin-right: 20px;
      }

      span {
        line-height: 21px;
        color: #2f75da;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
        text-decoration: underline;
      }

      .patient-id {
        color: #979797;
        font-size: 14px;
      }
    }
  }

  .patient-info {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tab-content {
      .property-segment {
        margin-bottom: 32px !important;
        min-height: 48px;
      }
    }

    .nav-tabs {
      border-bottom: none;
      gap: 4px;
      background-color: #f2f2f7;
      border-radius: 8px;
      padding: 4px;
      margin-bottom: 35px;
    }

    .nav-link {
      background-color: transparent;
      color: rgba(117, 117, 117, 1);
      width: 13vw;
      min-width: 140px;
      border-radius: 8px;

      &.active {
        background-color: rgba(47, 117, 218, 1);
        color: white;
      }
    }

    .patient-info-window {
      width: 100%;
      height: 100%;

      .demographic {
        display: flex;
        flex-direction: column;
        gap: 36px;
      }
    }

    .exam-result-title {
      font-size: var(--global-font-size-small-big);
      font-weight: 700;
    }

    .exam-graph-container {
      width: 100%;

      .exam-graph-svg {
        width: 100%;
        height: 15rem;
        max-width: 80rem;
      }
    }

    .exam-footer {
      textarea {
        width: 100%;
        background-color: vars.$secondaryBackground;
        color: vars.$dark;
        height: 8rem;
      }

      .custom-input-label {
        color: vars.$dark !important;
      }

      .max-width {
        width: 100%;
        height: 100%;
      }

      button {
        height: 3rem;
        width: 15rem;
      }
    }
  }

  .custom-row-gap {
    margin-bottom: 52px;
  }

  .custom-row-gap:last-child {
    margin-bottom: 0;
  }
}

.clinical-info-one {
  height: 100%;
}

.patient-info-container {
  width: 13vw;
  min-width: 980px;
}


.scrollable-container {
  max-height: 465px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }

  scrollbar-width: none;

  -ms-overflow-style: none;
}
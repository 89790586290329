.custom-input-base {
  background-color: white;
  &.set-label {
    .custom-input-label-base {
      left: 20px;
      top: 20px;
    }
    input {
      padding-top: 20px;
      background-color: transparent !important;
    }
  }

  &:has(input:focus) {
    .custom-input-label, .custom-input-label-number {
      left: 20px;
      top: 20px;
      color: #757575;
    }
    input {
      padding-top: 20px;
      background-color: transparent !important;
    }
  }

  

  input {
    background-color: transparent;

    &::placeholder {
      color: #757575;
      font-weight: 400;
    }
  }

  .required-asterisk {
    color: #ff3b30;
    position: absolute;
    top: 0px;
    right: -8px;
  }

  .custom-input-label-base {
    position: absolute;
    left: 20px;
    top: 50%;
    font-weight: 400;
    transform: translateY(-50%);
    color: #757575;
  }

  .custom-input-label-number {
    top: 32px;
  }

  .input-icon {
    position: absolute;
    left: 85%;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    
    svg{
      width: 1.8rem;
      height: 1.8rem;
    }
  }

}


input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}


.invalid-input{
  color: red;
  font-size: .9rem;
}

.info-input{
  color: #757575;
  font-size: .9rem;
}
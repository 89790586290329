.custom-sign-modal {
  .modal-content {
    height: 25rem;
  }


  .custom-modal-footer {
    flex-wrap: nowrap;
    justify-content: space-between;
    button {
      height: 3rem;
      width: 10rem;
    }
  }

  .custom-modal-body {
    width: 100%;
    height: 200px;
    align-items: unset;

    canvas {
      border: 1px solid #ccc;
    }
  }
}

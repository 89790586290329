@use '../common/consts/colors.scss' as vars;

@font-face {
    font-family: "Poppins-Regular";
    src: url("../common/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("../common/fonts/Inter-Variable.ttf");
}

.inter-font{
    font-family: Inter;
}

body {
    font-family: Poppins-Regular !important;
}

.primary-button{
    background-color: vars.$blueMain;
    color: white;
}

.secondary-button{
    background-color: vars.$blueLight;
    color: vars.$blueMain;
}


.input-group-text{
    background-color: white !important;
    border-left: none !important;
}

.test{
    border: 1px solid red!important;
}

.test2{
    border: 1px solid blue!important;
}
.test3{
    border: 1px solid green!important;
}


.pointer{
    cursor: pointer;
}

.background-primary-white{
    background-color: vars.$white;
}

// TEST
.app-scrollable-container {
    height: 90vh;       
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    body {
      font-size: 0.9rem;  /* Adjust font size */
    }
    .container {
      max-width: 90%;    /* Adjust width to accommodate smaller logical resolution */
    }
  }

  .rounded-status {
    background-color: vars.$statusBorderColor;
    color: vars.$statusTextColor;
    padding: 0px 10px;
    border-radius: 100px;
    line-height: 21px;
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 25.5px;
    width: min-content;
}
.modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000;
}

.base-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 502px;
  display: flex;
  flex-direction: column;
  height: max-content;
  text-align: center;
  position: relative;
  gap: 16px;
  box-sizing: border-box;
}

.modal-content {
  gap: 16px;
}

.modal-content-no-children{
  gap: 0!important;
  height: 18rem!important;
}

.modal-header, .title-in-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title-in-header{
  justify-content: unset!important;
}

.modal-close {
  border: none;
  position: relative;
  background: none;
  width: fit-content;
  font-size: 24px;
  height: fit-content;
  cursor: pointer;
  margin-left: auto;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .modal-children{
    width: 100%;

    .radio-group{
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }
}

.modal-footer {
  .custom-button button {
    width: 10rem;
  }
}


.modal-icon svg{
  width: 85px;
  height: 65px;
}


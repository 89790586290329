@use '../../common/consts/colors.scss' as color;


.custom-filter-container {
    width: 250px;
    height: 100%;
    display: flex;
    position: absolute;
    background-color: #F2F2F7;
    box-shadow: 0px 4px 29px 0px rgba(145, 151, 179, 0.49);
    right: 0px;
    top: 0px;
    z-index: 1000;
    flex-direction: column;
    border-radius: 20px;

    /* Ensure the filter starts off-screen */
    transform: translateX(200%);
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for sliding */

    /* When the filter is open, slide it into view */
    &.open {
        transform: translateX(0);
    }

    .filters-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 26px 15px;
        align-items: center;
        border-bottom: 1px solid #D1D1D6;
        height: 76px;
    }

    .header-title {
        display: flex;
        color: black;
        font-weight: 600;
        font-size: var(--global-font-size-small-big);
        gap: 10px;
        align-items: center;
    }

    .filter-options-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80%;
        overflow-y: auto;

        .option-label {
            color: #272727;
            font-size: 15px;
            line-height: 21px;
        }

        .filter-option {
            display: flex;
            width: 100%;
            cursor: pointer;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #D1D1D6;

            .filter-cell {
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: max-content;

                &[title] {
                    cursor: pointer;
                }

                &[title]:hover::after {
                    content: attr(title);
                    position: absolute;
                    background-color: #0000006d;
                    color: #fff;
                    padding: 5px;
                    border-radius: 3px;
                    top: 100%;
                    left: 0;
                    white-space: nowrap;
                    z-index: 10;
                }
            }
        }

        .filter-options {
            width: 100%;
            height: 100%;
        }

        .embedded-filter-options {
            .filter-option {
                justify-content: flex-start;
                height: 50px;
                align-items: center;
            }
        }

        .filter-footer {
            font-size: var(--global-font-size-medium);

            .link {
                color: color.$blueMain;
                cursor: pointer;
            }
        }
    }
}
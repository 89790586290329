.general-container {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .general-header {
    position: relative;
    background-color: #fbfbfb;
    height: 146px;
    width: 100%;
    display: flex;
    padding: 2.5rem 0 0 6rem;
    flex-direction: column;

    h1 {
      font-size: var(--global-header-one-font-size);
    }

    span {
      font-size: var(--global-font-size);
      color: #2f75da;
      cursor: pointer;
      text-decoration: underline;
    }

    .min-content {
      width: min-content;
      .battery-status {
          text-decoration: none;
          color: black;
      }
    }

    .back-button-container {
      position: absolute;
      width: 50px;
      height: 20px;
      left: 20px;
      top: 15px;

      .back-span {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #2f75da;
        font-size: var(--global-font-size);
        font-weight: 500;
        
      }
    }

  }
  .information-content {
    margin: 3rem 7rem;
  }
}

@use '../../common/consts/colors.scss' as vars;

.main-home-container {

    background-color: vars.$secondaryBackground;
    

    .header {
        width: 100%;
        height: 17%;
        padding-top: 29px;
        
        .logo {
            width: 10%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: "center";
        }
    }

    .content-container {
        background-color: transparent;

        width: 95%;
        height: calc(100vh - 161px);
        position: absolute;
        left: 50%;
        top: 120px;
        transform: translate(-50%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 3%;

        .content {
            border-radius: 10px;
            width: 80%;
            height: 100%;
            flex-grow: 1;
            background-color: vars.$secondaryBackground;

            box-shadow: 0px 4px 29px 0px rgba(145, 151, 179, 0.49)

        }
    }
}
.user-profile-container {
  padding: 0 10rem;
  background-color: #FFFFFF;
  border-radius: 9px;
  height: 100%;


  .btn-primary {
    width: 6rem;
    height: 3rem;
  }

  .input {
    width: 10rem;
    height: 3rem;
  }

  .btn-link {
    padding: 0;
  }

  .cancel-text {
    color: gray;
  }

  .custom-user-profile-dropdown {
    width: 100%;
    height: 100%;
    .custom-select {
      width: 12rem;
      height: 3.4rem;
    }
    .custom-dropdown-arrow {
      left: 10.4rem;
    }
  }
}

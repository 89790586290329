$secondaryBackground: #F2F2F7;
$lightBackground: #FBFBFB;
$borderColor: #E0E0E0;
$primaryBackground: #D1D1D6;
$grayBorderColor: #D0D5DD;
$statusTextColor: #2F75DA;
$statusBorderColor: rgba(47, 117, 218, 0.2);
$blueMain: rgba(47, 117, 218, 1);
$blueLight: rgba(220, 232, 249, 1);
$white: rgba(255, 255, 255, 1);
$lightgrey: rgba(251, 251, 251, 1);
$mediumgrey: rgba(209, 209, 214, 1);
$dark: rgba(0, 0, 0, 1);
$error: #FF3B30;
// Toast
$toastSuccessColor: #D0F1D8;
$toastSuccessBorderColor: #34C759;
$toastWarningColor: #FFF4C7;
$toastWarningBorderColor: #FFCC00;
$toastErrorColor: #FFD5DD;
$toastErrorBorderColor: #FF3B30;
$toastInfoColor: #C9EEFF;
$toastInfoBorderColor: #32ADE6;
$toastGeneralColor: #F6F2F3;
$toastGeneralBorderColor: #8E8E93;

:export {
    primaryBackground: $primaryBackground;
    lightBackground: $lightBackground;
    secondaryBackground: $secondaryBackground;
    borderColor: $borderColor;
    blueMain: $blueMain;
    blueLight: $blueLight;
    white: $white;
    statusBorderColor: $statusBorderColor;
    statusTextColor: $statusTextColor;
    grayBorderColor: $grayBorderColor;
    dark: $dark;
    lightgrey: $lightgrey;
    mediumgrey: $mediumgrey;
    error: $error;
    toastSuccessColor: $toastSuccessColor;
    toastSuccessBorderColor: $toastSuccessBorderColor;
    toastWarningColor: $toastWarningColor;
    toastWarningBorderColor: $toastWarningBorderColor;
    toastErrorColor: $toastErrorColor;
    toastErrorBorderColor: $toastErrorBorderColor;
    toastInfoColor: $toastInfoColor;
    toastInfoBorderColor: $toastInfoBorderColor;
    toastGeneralColor: $toastGeneralColor;
    toastGeneralBorderColor: $toastGeneralBorderColor;
}
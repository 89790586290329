.generic-form {
  .translate-offset {
    transform: translateX(-100%)
  }

  .custom-input.is-invalid,
  .custom-select.is-invalid {
    border-color: #dc3545;
    background-color: #f8d7da;

  }

  input {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .invalid-feedback {
    display: flex;
    color: rgb(220, 53, 69);
    padding-left: 20px;
  }



  .has-nested {
    padding-left: 40px;
    position: relative;
  }

  .nest-line {
    position: absolute;
    left: 22px;
    top: -20px;
  }

  .info-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    position: relative;
    height: 65px;
    width: 100%;
    padding-left: 15px;

    .form-label {
      position: absolute;
      font-size: 1.1rem;
      color: #6c757d;  
      top:5px;
    }

    .info-value{
      padding-top: 20px;
      text-align: start;
    }
  }
}

.required-asterisk{
  color: #ff3b30;
}
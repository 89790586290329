@use '../../../common/consts/colors.scss' as vars;


.table-row-main-container {
  position: relative;
  background-color: white;
  width: 95%;
  min-height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding-inline: 1%;
  border-radius: 10px;
  border: 1px solid vars.$borderColor;

  .table-cell {
    position: relative;
    min-width: 8vw;
    max-width: 8vw;
    justify-content: flex-start;
    display: inline-block;
    font-weight: 600;
    color: vars.$dark;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &[title] {
      cursor: pointer;
    }

    &[title]:hover::after {
      content: attr(title);
      position: absolute;
      background-color: #0000006d;
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      top: 100%;
      left: 0;
      white-space: nowrap;
      z-index: 10;
    }
  }

  .table-cell-extend {
    min-width: 15rem!important;
  }

  .settings {
    cursor: pointer;
    position: absolute;
    right: 1rem;

    .settings-dropdown {
      position: absolute;
      top: 100%;
      right: 45%;
      background: white;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 100;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 250px;
      height: max-content;

      hr {
        margin: 0;
      }
    }

    .settings-button {
      color: vars.$dark;
      background: none;
      border: none;
      text-align: left;
      padding: 4px 8px;
      cursor: pointer;
      width: 100%;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 0.5rem;
      }

      svg {
        width: 23px;
        height: 23px;
      }
    }

  }
}
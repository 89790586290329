.btn-radio {
    &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    
      // Second child
      &:nth-child(2) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
  
    padding: 10px 20px;
    width: 109px;
    height: 36px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background-color: white;
    align-items: center;
    color: rgba(117, 117, 117, 1);
    &:focus {
      box-shadow: none;
    }
  
    input[type="radio"] {
      display: none; // Hide default radio
    }
  
    &.active {
      color: rgba(47, 117, 218, 1) !important;
      border: solid rgba(47, 117, 218, 1) 2px;
      background-color: white ;
    }
    
  
    &:not(.active) {
      color: #6c757d;
      border-color: #6c757d; 
    }

    &:hover{
        background-color: white;
    }
  }
  
.react-datepicker-popper {
  z-index: 100000000000 !important;
}

.custom-date-picker-container-component {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  flex-direction: column;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .render-value {
    color: black;
    display: flex;
    position: absolute;
    top: 30px;
    z-index: 100;
    background-color: white;
    width: 50%;
    left: 20px;
  }

  .custom-date-picker {
    height: 64px;
    position: relative;
    width: 100%;
  }

  .custom-date-picker-label {
    .required-asterisk {
      color: #ff3b30;
    }

    position: absolute;
    left: 20px;
    color: #757575;
    top: 20px;

    transform: translateY(-50%);
    z-index: 200;
  }

  input {
    padding: 20px 20px 0px 20px;
    height: 65px;
    width: 100% !important;
    font-size: 16px;
  }

  .invalid-feedback {
    display: flex;
  }

  .date-picker-select-image {
    position: absolute;
    right: 10px;
    top: 1px;

    width: 1.3rem;
    height: 1.3rem;
    transform: rotate(270deg);
  }

  .date-picker-icon {
    position: absolute;
    z-index: 100;
    top: 9px;
    left: 10px;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .react-datepicker__input-container input::placeholder {
    padding-left: 10px;
  }

}

// .react-datepicker__year-dropdown::before {
//   content: "▲";
//   font-size: 14px;
//   position: absolute;
//   top: 5px;
//   left: 50%;
//   transform: translateX(-50%);
//   color: #666;
//   pointer-events: none; 
// }

// .react-datepicker__year-dropdown::after {
//   content: "▼";
//   font-size: 14px;
//   position: absolute;
//   bottom: 5px;
//   left: 50%;
//   transform: translateX(-50%);
//   color: #666;
//   pointer-events: none;
// }
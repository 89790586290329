@use "../../common/consts/colors.scss" as colors;

.menu-side-bar-main-container {
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  gap: 3%;
  padding-inline: 1%;
  padding-block: 1%;
  box-shadow: 0px 4px 29px 0px rgba(145, 151, 179, 0.49);
  position: relative;

  background: linear-gradient(
    to bottom,
    colors.$white 99%,
    transparent 99%
  ),
  linear-gradient(
    136.79deg,
    #2232c0 80%,
    #3fd6ff 100.16%,
    #3fcbc9 154.32%
  );

  transition: width 0.2s ease-in-out;

  &.collapsed {
    width: 5%;
  }

  /* Remove disableAnimation class or repurpose it */
  /* If you need a default state without transition, set the initial width here */
  width: 15%;

  .menu-headline {
    display: flex;
    flex-direction: row;

    .collapsed-button {
      width: 2.5rem;
      height: 2.5rem;
      background-color: white;
      position: relative;
      right: -90%;
      border: 1px solid #d1d1d6;
      border-radius: 10px;
    }

    .text {
      font-weight: 600;
      width: 10%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .link-style {
    text-decoration: none;
    color: black;
    margin-inline-start: 1em;
    font-weight: 600;
    white-space: nowrap;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    gap: 20px;
    margin-bottom: 10px;

    .item-icon {
      width: 30px;
      margin-left: -3px;
    }
  }

  .user-info-container {
    margin-top: auto;
    width: 100%;
    height: 10%;
    border-top: solid 1px colors.$grayBorderColor;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
    overflow: hidden;
    justify-content: center;
    cursor: pointer;

    .user-text-container {
      display: flex;
      flex-direction: column;
      width: 70%;
      height: 60%;

      .full-name-text {
        font-weight: 800;
        
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &[title] {
          cursor: pointer; 
        }
    
        &[title]:hover::after {
          content: attr(title);
          position: absolute;
          background-color: #0000006d;
          color: #fff;
          padding: 5px;
          border-radius: 3px;
          top: 100%;
          left: 0;
          white-space: nowrap;
          z-index: 10;
        }

        .arrow-icon {
          transform: rotate(180deg);
        }
      }

      .user-name-text {
        font-size: 13px;
        overflow: hidden;

        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &[title] {
          cursor: pointer; 
        }
    
        &[title]:hover::after {
          content: attr(title);
          position: absolute;
          background-color: #0000006d;
          color: #fff;
          padding: 5px;
          border-radius: 3px;
          top: 100%;
          left: 0;
          white-space: nowrap;
          z-index: 10;
        }
      }
    }
  }

  .menu-text {
    overflow: hidden;
  }

  .active-tab {
    color: colors.$statusTextColor;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.generic-cards-scrollable-container {
    height: 465px;
    overflow-y: auto;
    padding: 1rem;
    margin: 0 auto;
}
  
  .generic-cards-scrollable-container::-webkit-scrollbar {
    display: none;
  }
  
  .generic-cards-scrollable-container::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
  
  .custom-card {
    cursor: pointer;
    width: 200px;
  }
  
  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .svg-container svg {
    height: 100%;
    width: auto;
  }
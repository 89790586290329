.graphs-viewer {
    display: flex; /* Switch to flexbox */
    flex-wrap: wrap; /* Ensure items wrap to the next line if needed */
    gap: 20px; /* Spacing between items */
    width: 100%;
    max-height: 100%;
    justify-content: space-between; /* Space between items on larger screens */

    /* Flex children */
    > div {
        flex: 1 1 calc(33.33% - 20px); /* Take 1/3 width with gap considered */
        max-width: calc(33.33% - 20px); /* Ensure it doesn't exceed 1/3 width */
    }

    /* For smaller screens */
    @media screen and (max-width: 1500px) {
        > div {
            flex: 1 1 calc(50% - 20px); /* Take half the width on smaller screens */
            max-width: calc(50% - 20px);
        }
    }

    @media screen and (max-width: 480px) {
        > div {
            flex: 1 1 100%; /* Take full width on the smallest screens */
            max-width: 100%;
        }
    }
}
@use '../../../common/consts/colors.scss' as vars;

.table-filters-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-inline: 2.5%;

    .title-wrapper {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        // padding-inline-start: 1%;


        .patients-list-title {

            font-weight: 900;
        }


    }

    .filters-wrapper {
        width: min-content;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 2%;
        // padding: 1%;


        .trash-button {
            width: 3rem;
            height: 2.5rem;
            background-color: white;
            border: 1px, solid, #D1D1D6;
            border-radius: 10px;
            padding: .5rem;

            cursor: pointer;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

            svg{
              width: 1.3rem;
              height: 1.3rem;
            }
          
            &:hover {
              background-color: #2F75DA;
              color: white;
          
              svg {
                path {
                  stroke: white;
                  transition: stroke 0.3s ease-in-out; 
                }
              }
            }

        }

        .filter-table-buttons, .version-table-buttons {
            width: 6.5rem;
            height: 2.5rem;
            background-color: white;
            border: 1px solid #D1D1D6; 
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          
            &:hover {
              background-color: #2F75DA;
              color: white;
          
              svg {
                path {
                  stroke: white;
                  transition: stroke 0.3s ease-in-out; 
                }
              }
            }
          }

          .version-table-buttons{
            width: 8rem!important;
            color: black !important;

            &:hover {
              background-color: #2F75DA;
              color: white!important;
          
              svg {
                path {
                  stroke: none!important;
                  transition: none!important; 
                }
              }
            }
          }


          .active-filters-button{
            border: 2px solid vars.$blueMain; 
            position: relative;
            
            .filters-count{
              font-size: var(--global-font-size-small);
              z-index: 10000000;
              border: 1px solid vars.$blueMain;
              border-radius: 100%;
              position: absolute;
              width: 1.5rem;
              height: 1.5rem;
              text-align: center;
              line-height: 1.5rem;
              bottom: 65%;
              right: -10%;
              background-color: vars.$white;
            }
          }
          

        .search-input-wrapper {
            width: 313px;
            height: 2.5rem;
            border-radius: 8px;
            padding: 8px 16px;
            border: 1px solid #D1D1D6;
            display: flex;
            background-color: white;
            span{
                padding: 0px;
            }
            .search-input {
                height: 100%;
                &:focus{
                    box-shadow: none;
                }
            }
            
        }

        .add-button {
            width: 10rem;
            height: 2.5rem;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            font-size: 1rem;
            font-weight: 600;
            white-space: nowrap;
        }
    }
}

.flex-center-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.temp{
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
@use "../../common/consts/colors.scss" as color;
@use "../../common/consts/fontSizing.scss" as font;

.dashboard-page-main-container {
  /* Webkit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Hides the scrollbar */
  -ms-overflow-style: none; /* Hides the scrollbar */

  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: color.$lightgrey;
  padding: 29px;
  border-radius: 8px;
  .table-header-main-container {
    padding: 0px;
    background-color: transparent;
  }
  .table-header {
    width: 100%;
    height: 20%;
    background-color: transparent;
    .card-container {
      font-size: var(--global-font-size);
    }
  }

  .dashboard-filters {
    height: 100px;

    .date-picker-container {
      width: 20%;
      height: 60%;

      .react-datepicker-popper {
        left: 50% !important;
        svg {
          display: none;
        }
      }

      .react-datepicker__input-container input {
        height: 3rem;
        padding-bottom: 1.1rem;
        padding-left: 2rem;
      }

      .custom-date-picker-label {
        margin-top: 0.3rem;
        margin-left: 1.4rem;
      }
    }

    .sites-filter-container {
      button {
        border: 1px solid color.$borderColor;
        background-color: color.$white;
        // width: 8rem;
        // height: 3rem;
      }

      .filters-wrapper {
        margin-left: 3rem;
        align-items: start;
        margin-top: 0.5rem;

        .filter-table-buttons {
          margin-left: 0.8rem;
        }

        .export-button {
          width: 5rem;
        }
        .filter-button {
          min-width: 8rem;
        }
      }
    }
  }
}

.custom-date-range-picker-container {
  .custom-fz {
    font-size: var(--global-font-size-small);
  }

  input {
    width: 60px;
    height: 35px;
    margin-right: 0.8rem;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid color.$primaryBackground;

    &:focus {
      outline: none;
      border: 1px solid color.$primaryBackground;
      box-shadow: 0 0 4px color.$primaryBackground;
    }
  }

  .input-error {
    border: 1px solid color.$error;

    &:focus {
      outline: none;
      border: 1px solid color.$error;
      box-shadow: 0 0 4px color.$primaryBackground;
    }
  }

  .range-btn {
    width: 20px;
    height: 20px;
  }

  .custom-btn {
    width: 60px;
    height: 35px;
  }
}

.dashboard-page-main-container
  .dashboard-filters
  .sites-filter-container
  .btn-primary {
  background-color: #0d6efd;
}

.dashboard-page-main-container
  .dashboard-filters
  .sites-filter-container
  .modal-close {
    border: none;
}

.autocomplete-wrapper {
  position: relative;
  background-color: white;

  &.set-label {
    .custom-autocomplete-label {
      left: 17px;
      top: 15px;
      color: #757575;
    }

    input {
      padding-top: 20px;
      background-color: transparent !important;
    }
  }

  input {
    height: 65px;
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: transparent;

    &::placeholder {
      color: transparent; // Hide placeholder when label is active
    }

    &:focus {
      outline: none;
      border-color: #007bff; // Adjust color for focus state
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2); // Light blue focus effect
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  .custom-autocomplete-label {
    position: absolute;
    left: 20px;
    top: 50%;
    font-weight: 400;
    transform: translateY(-50%);
    color: #757575;
    transition: all 0.2s ease-in-out;
  }

  .required-asterisk {
    color: #ff3b30;
    position: absolute;
    top: 0px;
    right: -8px;
  }

  .input-icon {
    position: absolute;
    left: 85%;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .autocomplete-options {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    list-style: none;

    .autocomplete-option {
      padding: 10px 16px;
      font-size: 1rem;
      color: #333;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .invalid-input {
    color: red;
    font-size: 0.9rem;
    margin-top: 4px;
  }

  .autocomplete-arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: #757575;
    }
  }
}

.settings-container{
  padding: 0 10rem;
  background-color: #FFFFFF;
  height: 100%;
  border-radius: 8px;

  .input-container {
    width: 100%;
    height: 100%;
    .custom-select {
      width: 12rem;
      height: 3rem;
    }
    .custom-dropdown-arrow {
      left: 10.4rem;
    }
    .dropdown-wrapper.set-label select {
      padding: 0 0 0 20px;
    }
  }

  .email-section {
    .email-input-wrapper {
      display: flex;
      align-items: center;
  
      .email-input {
        flex: 1;
        margin-right: 10px;
      }
  
      .add-email-button {
        white-space: nowrap;
      }
    }
  
    .email-list {
      margin-top: 15px;
  
      .email-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
  
        .email-text {
          flex: 1;
        }
  
        .remove-email-button {
          color: #dc3545;
          text-decoration: none;
        }
      }
    }
  }
}

@use '../../../common/consts/colors.scss' as vars;

.sort-table-main-container {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    padding-inline: 1%;

    .sort-icon {
        // margin: 1%;
        width: 1.2rem;
        height: 1.2rem;
        margin-inline-start: 0.3rem;

    }

    .bold-text {
        font-weight: 900;
    }

    .sort-cell{
        font-size: var(--global-font-size-small);
        min-width: 8vw;
        justify-content: flex-start;
        display: flex;
        cursor: pointer;
    }

    .sort-cell-extend{
        min-width: 15rem!important;
    }

    .cell {
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60%;
        flex: 1;

        :hover {
            cursor: pointer;
        }
    }



    .settings-icon {
        margin-inline-start: auto;
    }

    .rotate {
        transform: rotate(180deg);

    }
}
.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    background: linear-gradient(to right, #2b83f6, #39e0d5);

    .inner-avatar {
        background-color: lightgray;
        width: 85%;
        height: 85%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}
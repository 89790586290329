.dropdown-wrapper {
  position: relative;
  width: 100%;
  z-index: 999999 !important;

  &.set-label {
    z-index: 99999999 !important; 

    .custom-dropown-label {
      z-index: 100000000 !important;
      position: absolute;
      left: 20px;
      top: 20px;
      color: #757575;
      cursor: default;
    }

    select {
      padding-top: 20px;
      background-color: transparent !important;
      z-index: 5;
    }
  }


  &:has(select:focus) {
    .custom-input-label {
      left: 20px;
      top: 20px;
      font-size: 12px;
      color: #757575;
    }

    input {
      padding-top: 20px;
      background-color: transparent !important;
    }
  }

  select {
    background-color: transparent !important;
    padding-left: 20px;
    z-index: 1;
    display: block;
    position: relative;
    color: black !important;
  }

  .required-asterisk {
    color: #FF3B30;
    position: relative;
    top: 0px;
    right: -8px;
  }

  .custom-dropown-label {
    position: absolute;

    color: #757575;
    z-index: 1;
    cursor: default;
    transform: translateY(-50%);
    left: 20px;
    top: 32px;
  }

  .custom-dropdown-arrow {
    z-index: 99999999 !important; 
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .custom-input {
    padding-top: 1rem;
    z-index: 999999999999 !important;
  }

  .autocomplete-container {
    position: relative;
    z-index: 999999 !important;
  }

  .autocomplete-options {
    position: absolute;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    // background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 999999999999 !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: rgba(255, 0, 0, 0.3) !important; /* Red transparent background */

    padding: 0;
    margin: 0;
    list-style: none;

    .autocomplete-option {
      padding: 5px 16px;
      font-size: 1rem;
      color: #333;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  input{
    z-index: 1;
  }
}
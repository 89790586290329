.GraphCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  align-items: center;
  width: auto;
  height: 303px;
  border: rgba(209, 209, 214, 1) solid 1px;
  border-radius: 8px;
  position: relative;
  padding: 14px 17px;
  .title {
    width: 100%;
    height: 55px;
    line-height: 21px;
    font-weight: 600;
    font-size: 16px;
  }

  .circle-container{
    width: 95%;
    height: 100%;
  }
}

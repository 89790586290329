.add-patient-container{
    border-radius: 10px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: 41px calc(13%);
    .add-patient-inner-container{
        margin-bottom: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .add-patient-header{
            font-size: 28px;
            font-weight: 500;
            line-height: normal;
            line-height: 28px;
        }
        .add-patient-stage-title{
            display: flex;
            gap: 12px;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px;
            align-items: center;
        }
       
    }
    .address-fields-container{
        form{
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
      
    }
    .add-patient-button-group{
      display: flex;
      gap: 12px;
      align-self: flex-end;
    }
    .custom-divider {
        border-top: 1px solid #E5E5EA;
        width: 100%;
        margin: 35px 0px 27px;
      }
    .toggle-question{
        display: flex;
        justify-content: space-between;
    }
    .toggle-question-container{
        gap: 9px;
        display: flex;
        flex-direction: column;
    }
}

@use "../../common/consts/colors.scss" as colors;

.toast-custom-container {
  color: colors.$dark !important;
  width: 500px;
  height: 65px;
  border-radius: 10px !important;

  .toast-icon {
    width: 25px;
    height: 25px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}


.toast-success {
  background-color: colors.$toastSuccessColor !important;
  border-left: 10px solid colors.$toastSuccessBorderColor !important;
}

.toast-error {
  background-color: colors.$toastErrorColor !important;
  border-left: 10px solid colors.$toastErrorBorderColor !important;
}

.toast-information {
  background-color: colors.$toastInfoColor !important;
  border-left: 10px solid colors.$toastInfoBorderColor !important;
}

.toast-warning {
  background-color: colors.$toastWarningColor !important;
  border-left: 10px solid colors.$toastWarningBorderColor !important;
}

.toast-general {
  background-color: colors.$toastGeneralColor !important;
  border-left: 10px solid colors.$toastGeneralBorderColor !important;
}

@use "../../../common/consts/colors.scss" as color;

.cards-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    .table-header-title {
        padding: 40px 25px;
        background-color: color.$secondaryBackground;
        font-weight: bold;
    }

    .custom-tabs {
        gap: 10px;
        border-bottom: none !important;
        padding: 0 20px;

        .nav-item {
            flex: 1;
            text-align: center;
            width: 100%;
        }


        .nav-link {
            background-color: transparent;
            color: rgba(117, 117, 117, 1);
            font-size: 1.2rem;
            padding: 15px 40px;
            width: 100%!important;
            border: 2px solid var(--bs-nav-tabs-link-active-border-color);
            border-radius: 8px;
            font-weight: bold;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .nav-link.active {
            background-color: rgba(47, 117, 218, 1);
            color: white;
        }
    }

    .card-container {
        padding: 10px;
        height: 100%;
        flex: 1;
        border-radius: 10px;
        background-color: color.$white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        border: 1px solid color.$mediumgrey;

        .card-headline-container {
            width: 100%;
            height: 30%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .card-headline-texts {

                height: 100%;
                width: 70%;

                .card-headline-text {
                    white-space: nowrap;

                    font-size: var(--global-font-size-small-med);
                    margin: 0;

                }

                .card-headline-explain {
                    line-height: 1rem;
                    font-size: var(--global-font-size-medium);
                }
            }

        }

        .card-value {
            width: 100%;
            height: 30%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .card-number {
                font-size: var(--global-font-size-big);
                align-self: center;
            }

            .card-view-all {
                align-self: flex-end;



            }

            :hover {

                cursor: pointer;
            }


        }

    }
}
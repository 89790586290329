.circle-graph-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
  .graph{
    width: 50%;
    aspect-ratio: 1/1;
    .doughnut-graph{
        flex-shrink: 1;
    }
  }
  .label-value-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .label-color-group {
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  
    .color-marker {
      width: 3px;
      height: 10px;
      border-radius: 8px;
    }
  
    span {
      white-space: nowrap; /* Prevent wrapping */
      overflow: hidden; /* Hide overflow */
      text-overflow: ellipsis; /* Add ellipsis for overflowing text */
      max-width: 100px; /* Adjust based on available space */
      display: inline-block;
      position: relative; /* For tooltip positioning */
      cursor: pointer;
    }
  
    span:hover::after {
      content: attr(data-tooltip); /* Use the `data-tooltip` attribute for the full text */
      position: absolute;
      background: #333;
      color: #fff;
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 4px;
      top: 120%; /* Position below the text */
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap; /* Prevent wrapping in the tooltip */
      z-index: 10;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }
  
    span:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }

  .legend-list {
    list-style: none;
    gap: 0px 10px;
    padding-left: 0;
    margin-bottom: 0px;
    display: grid;
    width: fit-content;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(min-content + 10px), 1fr)
    ); // Defines how many columns based on container width
    grid-auto-rows: 1fr; // Ensures equal height for rows
    grid-auto-flow: column; // Items will fill the column first
    grid-template-rows: repeat(5, 1fr); // Create 5 rows per column
    &.six-plus {
      grid-template-rows: repeat(5, 1fr);
    }
    li {
      margin-bottom: 8px;
      span {
        color: #757575;
        font-size: 12px;
        font-weight: 600;
        line-height: 21px;
      }
      strong {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #272727;
        margin-left: 10px;
      }
    }
  }
}

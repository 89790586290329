.right-part {
  width: 50%;

  // .inner-right-part {
  //     height: 95%;
  //     width: 95%;
  //     border-radius: 10px;
  // }
}

.otp-container {
  .resend-link {
    font-size: var(--global-font-size);
    color: #2f75da;
    cursor: pointer;
    text-decoration: underline;
  }
}

.title-group {
  margin-bottom: 40px;
}

.group-spacing {
  margin-bottom: 20px;
}

.group-center {
  display: flex;
  justify-content: center;
}

.right-part {
    width: 50%;

    // .inner-right-part {
    //     height: 95%;
    //     width: 95%;
    //     border-radius: 10px;
    // }
}



.form-container {}

.title-group {
    margin-bottom: 40px
}

.group-spacing {
    margin-bottom: 20px;
}

.group-center {
    display: flex;
    justify-content: center;
}

.space {
    margin-inline-start: 5px;
}

.checkbox-wrapper {
    margin-bottom: 10px;

}

.underline {
    text-decoration: underline;
}
.right-part {
    width: 50%;

    // .inner-right-part {
    //     height: 95%;
    //     width: 95%;
    //     border-radius: 10px;
    // }

    .global-input{
        width: 28rem!important;
    }

    .reset-password-icon{
        width: 1.5rem;
        height: 1.5rem;
        top: 1.3rem;
        right: 1.5rem;
        cursor: pointer;
    }
}

.password-instructions-group {
    width: 27rem!important;
    margin-bottom: 2em;

    .password-instructions {
        // width: 50%;

        .req-done-color {
            color: #18C938;
        }

        .req-not-done-color {
            color: red;
        }

        .reqText {
            font-weight: 700;
            font-size: 1em;
        }
    }
}

.password-instructions-group {
    height: 3em;
}

.title-group {
    margin-bottom: 40px
}

.group-spacing {
    margin-bottom: 20px;
}

.group-center {
    display: flex;
    justify-content: center;
}

.space {
    margin-inline-start: 5px;
}

.checkbox-wrapper {
    margin-bottom: 10px;

}

.underline {
    text-decoration: underline;
}